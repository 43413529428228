@import "../shared";

.info-button {
  
  background: transparent;
  border: 0;
  outline: 0;
  width: 340px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px; // this svg has extra padding in the image
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: $z-index-map;

  @media (min-width: $mobile_breakpoint) {
    bottom: -40px; // this svg has extra padding in the image

  }
}



.info-button:hover {
  cursor: pointer;
}
