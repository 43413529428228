@import "../shared";

.intro {
  h2 {
    @include luti_font;

    position: fixed;
    bottom: 25vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 12em;
    z-index: $z-index-map;
    font-size: 1.7em;
    font-weight: 400;
    color: $intro_color;

    &.dark {
      color: $intro_color_dark;
    }

    .wf-loading & {
      opacity: 0;
    }

    .wf-active & {
      opacity: 1;
      transition: all $transition_speed linear;
    }

    &.hidden {
      opacity: 0;
      display: none;
    }

    &.enter {
      opacity: 0;
      display: block;
    }

    &.enter.enter-active {
      opacity: 1;
      transition: all $transition_speed linear;
    }

    &.enter-done {
      opacity: 1;
      display: block;
    }

    &.exit {
      opacity: 1;
    }

    &.exit-active {
      opacity: 0;
    }

    &.exit-done {
      opacity: 0;
      display: none;
    }
  }
}
