@import "../shared";

$content_width: 560px;
$content_height: 315px;

.page {
  @include luti_font;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  color: $page_color;

  &.dark {
    color: $page_color_dark;
  }

  .wrapper {
    width: 100%;
    max-width: 760px;
    margin: 85px 100px;
    padding: 20px 50px;
    background-color: $page_background;

    @media (max-width: $mobile_breakpoint) {
      margin: 60px 0px;
    }

    @media (min-width: $large_breakpoint) {
      padding: 30px 120px;
    }
  }

  &.dark {
    .wrapper {
      background-color: $page_background_dark;
    }
  }

  figure {
    margin: 0;
  }

  .embeds_wrapper {
    .figure-group {
      margin: 0;
    }

    figure:not(.figure-group) {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;

      margin: 0;
      margin-bottom: 15px;

      @media (max-width: $mobile_breakpoint) {
        padding-top: 0;
      }
    }

    figure:not(.figure-group) {
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    figcaption {
      margin-left: -6000px;
    }

    .caption {
      margin-bottom: 40px;
      font-family: roboto-condensed, sans-serif;
      font-weight: 300;
      font-size: 18px;
      text-transform: none;
    }
  }

  h1 {
    font-weight: 500;
    margin: 0;
    font-size: 75px;

    @media (max-width: $mobile_breakpoint) {
      font-size: 40px;
    }
  }

  h2 {
    font-weight: normal;
    font-size: 200%;
  }

  p {
    font-family: roboto-condensed, sans-serif;
    font-weight: 300;
    text-transform: none;
    font-size: 22px;
    line-height: 1.4;

    @media (max-width: $mobile_breakpoint) {
      font-size: 22px;
      line-height: 1;
    }
  }

  img {
    width: 100%;
  }

  a {
    color: $link;
    padding: 0 1px;
  }

  a:hover {
    color: $link_hover;
  }

  &.dark {
    a {
      color: $link_dark;
    }

    a:hover {
      color: $link_hover_dark;
    }
  }

  .contact {
  }

  .disclosure {
  }

  // web fonts
  .wf-loading & {
    opacity: 0;
  }

  .wf-active & {
    opacity: 1;
    transition: all $transition_speed linear;
  }
}
