@import "../shared";

$medium_breakpoint: 1250px;
$mobile_breakpoint: 1120px;

.nav {
  padding: 0;
  margin: 0;
}

ul.menu {
  @include luti_font;
  background: $menu_background_color;

  &.dark {
    @include luti_font_dark;
    background: $menu_background_color_dark;
  }

  margin: 0;
  padding: 6px 0;
  list-style-type: none;
  text-align: center;
  z-index: $z-index-map;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  font-size: $font_size;
  font-weight: 600;
  letter-spacing: 0.5px;

  // hamburger
  .menu-toggle {
    display: none;

    @media (max-width: $mobile_breakpoint) {
      display: inline;
      height: 16px;
      padding: 0 20px;
    }
  }

  li {
    @media (min-width: $mobile_breakpoint) {
      $top: 6px;

      .home {
        display: none;
      }

      .about {
        position: absolute;
        right: 10em;
        top: $top;
      }

      .thanks {
        position: absolute;
        right: 50px;
        top: $top;
      }
    }

    @media (min-width: $medium_breakpoint) {
      .about {
        right: 11.5em;
      }

      .thanks {
        right: 50px;
      }
    }

    @media (max-width: $mobile_breakpoint) {
      width: 100%;
      white-space: nowrap;

      &.hidden {
        opacity: 0;
        height: 0;
        visibility: hidden;
      }
    }
  }

  li:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile_breakpoint) {
      a {
        color: $color_selected;
      }
    }
  }

  &.dark {
    li:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobile_breakpoint) {
        a {
          color: $color_selected_dark;
        }
      }
    }
  }

  // mobile menu animations
  @media (max-width: $mobile_breakpoint) {
    &.enter li + li {
      opacity: 0;
      visibility: hidden;
    }

    &.enter.enter-active li + li {
      opacity: 1;
      visibility: visible;
      transition: all $transition_speed linear;
    }

    &.enter-done li + li {
      opacity: 1;
      visibility: visible;
    }

    &.exit li + li {
      opacity: 1;
      visibility: visible;
    }

    &.exit-active li + li {
      opacity: 0;
      visibility: hidden;
    }

    &.exit-done li + li {
      opacity: 0;
      visibility: hidden;
    }
  }

  li {
    a {
      color: $color;
      text-decoration: none;
    }

    &.selected a {
      color: $color_selected;
    }
  }

  li > a.hover {
    color: $color_selected;
    text-decoration: underline;
  }

  &.dark {
    li {
      a {
        color: $color_dark;
        text-decoration: none;
      }

      &.selected a {
        color: $color_selected_dark;
      }
    }

    li > a.hover {
      color: $color_selected_dark;
      text-decoration: underline;
    }
  }

  // animate visibility after font is loaded
  .wf-loading & {
    opacity: 0;
  }

  .wf-active & {
    opacity: 1;
    transition: all $transition_speed linear;
  }
}
