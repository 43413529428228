@import "shared";

$img_width: 1920px;
$img_height: 1080px;

body {
  font-family: "antarctican-headline", "Trebuchet MS", Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  scroll-behavior: smooth;
  overflow-x: hidden;

  background-color: $luti_background;
  background-color: $luti_background;

  video {
    background-color: $luti_background;
  }

  &.dark {
    background-color: $luti_background_dark;
    background-color: $luti_background_dark;

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      background-color: $zoom_btn_bg_dark;
      color: $zoom_btn_color_dark;
    }

    video {
      background-color: $luti_background_dark;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

#map,
.leaflet-container {
  height: calc(100vh - #{$menu-height});
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: $menu-height + 16;
  right: 0;
  left: 0;

  @media (min-width: $mobile_breakpoint) {
    height: 100vh;
  }
}

.leaflet-control-container {
  @media (max-width: $mobile_breakpoint) {
    display: none;
  }
}

.leaflet-control-attribution {
  display: none;
}

.video-loading {
}

.video-playing {
}

.connection-problem {
  // vertical center
  color: black;
  // border: 2px dotted red;
  // background-color: #faa;
  font-family: Helvetica, Arial;
  margin-left: 25px;
  margin-right: 25px;
}

.connection-problem-emoji {
  font-size: 300%;
}

.sound-debug {
  background-color: white;
  border: 1px solid gray;
  padding: 10px;
}
