@import "../shared";
@import "./shareButtons";

$max-height: 500px;
$overlay-height: auto;
$padding: 20px;
$speed: 600ms;

$offscreen: -$max-height;

.info-wrapper {
  background: $info_wrapper_bg;
  bottom: $offscreen;
  display: flex;
  font-size: 16px;
  height: $overlay-height;
  min-height: 6em;
  left: 0;
  max-height: 500px;
  padding: $padding;
  position: absolute;
  right: 0;
  z-index: $z-index-map + 1; // cover the button too

  &.dark {
    background: $info_wrapper_bg_dark;
  }

  @media (min-width: $mobile_breakpoint) {
    overflow-y: hidden;
  }

  @media (max-width: $mobile_breakpoint) {
    flex-direction: column;
  }

  .info-title {
    h1 {
      font-size: 40px;
      color: $title;
      margin: 0;
    }

    flex: 1;
  }

  &.dark {
    h1 {
      color: $title_dark;
    }
  }

  .info-description {
    color: $description;
    flex: 2;
    font-family: roboto-condensed, sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    text-transform: none;
  }

  &.dark {
    .info-description {
      color: $description_dark;
    }
  }

  .share-links,
  .share-links-mobile {
    flex: 1;
    display: flex;

    @media (min-width: $mobile_breakpoint) {
      margin-left: 10px;
    }
  }

  .share-links-mobile {
    display: block;
  }

  .share-links {
    justify-content: flex-end;
  }

  .share-links-mobile button {
    background-color: $share_links_bg_color;
    color: share_links_color;
    border: 0;
    font-family: roboto-condensed, sans-serif;
    font-size: 20px;
    font-weight: 400;
    outline: 0;
    padding: 10px;
    text-decoration: none;
    text-transform: none;
  }

  &.dark {
    .info-description {
      background-color: $share_links_bg_color_dark;
      color: share_links_color_dark;
    }
  }

  .share-links {
    @media (max-width: $mobile_breakpoint) {
      display: none;
    }
  }

  // hide/show animations
  .hidden & {
    bottom: $offscreen;
  }

  .enter & {
    bottom: $offscreen;
  }

  .enter.enter-active & {
    bottom: 0;
    transition: all $transition_speed ease;
  }

  .enter-done & {
    bottom: 0;
  }

  .exit & {
    bottom: 0;
  }

  .exit-active & {
    bottom: $offscreen;
    transition: all $transition_speed ease;
  }

  .exit-done & {
    bottom: $offscreen;
  }
}
